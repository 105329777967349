import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { defineStore } from 'pinia';
import { Ref, computed, ref, watch } from 'vue';
import { useApiClient } from '@api-client';
import daAuthClient, { User } from '@api-client/auth';
import type { User as ExternalUserType } from './types';
import { useAutoRefreshUserTokens } from './useAutoRefreshUserTokens';

export const useUserStore = defineStore('UserStore', () => {
    const currentUser = ref<User>();
    const userIsAdmin = computed(() => currentUser.value?.groups?.includes('org:darkhorse') || false);
    const { apiClient } = useApiClient();

    const loadUser = _.once(async () => {
        const user = await daAuthClient.currentUser;
        if (user && user.isAuthenticated) {
            currentUser.value = user;
            await apiClient.value.ensureAuthCookiesAreValid();
        } else {
            user.refresh().then(async () => {
                const u = await daAuthClient.currentUser;
                if (u.isAuthenticated) {
                    currentUser.value = u;
                    await apiClient.value.ensureAuthCookiesAreValid();
                }
            });
            daAuthClient.on('login', async () => {
                currentUser.value = await daAuthClient.currentUser;
                await apiClient.value.ensureAuthCookiesAreValid();
            });
        }
    });

    watch(() => currentUser.value, (user) => {
        if (!user || !user.isAuthenticated) {
            Sentry.setUser(null);
        } else {
            Sentry.setUser({
                username: user.username
            });
        }
    }, { immediate: true, flush: 'sync' });

    useAutoRefreshUserTokens(currentUser);

    /*
     * Logout the user and redirect to the login page
     */
    const logoutUser = async () => {
        // Logging out user has side-effect of redirecting to login page
        await daAuthClient.logout();
        currentUser.value = undefined;
    };

    return {
        currentUser: currentUser as Ref<ExternalUserType | undefined>,
        userIsAdmin,
        loadUser,
        logoutUser
    };
});
