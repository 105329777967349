import { DeepReadonly, WatchStopHandle, nextTick, watch } from 'vue';
import { User } from '@/stores/UserStore';
import { env } from '@api-client/envVars';
import { tracker } from '../tracker';
import { MaybeRef, unwrap } from './helpers';

export function useMixpanel(
    user: MaybeRef<User | undefined>
) {
    if (!env.MIXPANEL_TOKEN) {
        return;
    }

    console.info('Init mixpanel with', env.MIXPANEL_TOKEN);
    tracker.initMixpanel(env.MIXPANEL_TOKEN);

    // Declare the variable before we assign to it. If the user's already
    // signed in, the watcher will immediately run and potentially
    // try to reference the stopIdentifyWatcher before it's declared.
    let stopIdentifyWatcher: WatchStopHandle | undefined;
    stopIdentifyWatcher = undefined;

    stopIdentifyWatcher = watch(
        () => unwrap(user),
        async u => {
            if (!u) {
                return;
            }

            if (u.isAuthenticated) {
                const cognitoSub = u.userAttributes?.find(attr => attr.Name === 'sub')?.Value;
                if (cognitoSub) {
                    console.info('Identify mixpanel user as', cognitoSub);
                    tracker.identify(cognitoSub, {
                        username: u.username,
                        $name: u.username,
                        $email: u.email,
                        groups: u.groups
                    });
                }

                // Wait for the next tick so watch has a chance to return
                // and assign to stopIdentifyWatcher
                await nextTick();
                if (stopIdentifyWatcher) {
                    stopIdentifyWatcher();
                }
            }
        },
        { flush: 'pre', immediate: true }
    );
}
