import { DateTime } from 'luxon';
import { MaybeRefOrGetter, onScopeDispose, toValue, watch } from 'vue';
import type { User } from '@api-client/auth';
import { useApiClient } from '@api-client/composables';

/**
 * Set up an automatic refresh of the user's tokens when they expire so
 * we don't ever encounter expired tokens in the middle of a user session.
 *
 * @param user A ref or getter that returns the current user
 */
export function useAutoRefreshUserTokens(
    user: MaybeRefOrGetter<User | undefined>
) {
    const { apiClient } = useApiClient();

    let refreshUserTokensTimeout: NodeJS.Timeout | undefined;
    onScopeDispose(() => {
        if (refreshUserTokensTimeout) {
            clearTimeout(refreshUserTokensTimeout);
        }
    });

    async function refreshUserAfterTokensExpire(
        user: User
    ) {
        if (refreshUserTokensTimeout) {
            clearTimeout(refreshUserTokensTimeout);
        }

        const userAuth = await user.freshAuth;
        if (!userAuth) {
            /**
             * user.freshAuth will always either return a value, or attempt to redirect
             * the user to a login page. So we should only be able to hit this warning temporarily
             * while the user is being redirected, or if we've misconfigured the login redirect. In
             * either case, we don't have a valid user to work with, so we just warn and return. We'll
             * try again as soon as we have another user object.
             */
            console.warn('Couldn\'t get user auth, not refreshing user tokens');
            return;
        }

        // The exp (expiry) field of the JWT is documented here:
        // https://www.rfc-editor.org/rfc/rfc7519.html#section-4.1.4
        // It's in seconds since the Unix epoch.
        const accessTokenPayload = userAuth.accessToken.payload;
        const accessTokenExpiryTime = DateTime.fromSeconds(accessTokenPayload.exp);

        // If the token is already expired, refresh it immediately and try again in a few minutes
        if (accessTokenExpiryTime < DateTime.now()) {
            await user.refresh();
            await apiClient.value.ensureAuthCookiesAreValid();

            // Try again in 4 minutes. The shortest allowable expiry time in Cognito is 5 minutes,
            // so we want to make sure we try again within the next 5 minutes, but if something's going
            // wrong we want to make sure we don't get stuck in a loop and hammer the server.
            const FOUR_MINUTES = 4 * 60 * 1000;
            refreshUserTokensTimeout = setTimeout(async () => {
                refreshUserAfterTokensExpire(user);
            }, FOUR_MINUTES);
            return;
        }

        // Use the expiry time of the access token to calculate the timeout interval. We
        // want it to be a little longer than the actual expiry time because the user.refresh()
        // method is a no-op if the token hasn't yet expired
        const timeoutInterval = accessTokenExpiryTime
            .diffNow()
            .as('milliseconds')
            + 1000;

        refreshUserTokensTimeout = setTimeout(async () => {
            await user.refresh();
            await apiClient.value.ensureAuthCookiesAreValid();
            refreshUserAfterTokensExpire(user);
        }, timeoutInterval);
    }

    watch(() => toValue(user), (u) => {
        if (u) {
            refreshUserAfterTokensExpire(u);
        }
    }, { immediate: true, flush: 'sync' });
}
