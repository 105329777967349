import { TypeOf, compile, v } from 'suretype';

const structureRiskScoresByBoundary = v.array(
    v.object({
        id: v.number().integer().required(),
        adjustedRiskPercentile: v.number().integer().required(),
        riskPercentile: v.number().integer().required(),
    })
);

export const validateStructureRiskScoresByBoundary = compile(structureRiskScoresByBoundary, { ensure: true });
export type StructureRiskScoresByBoundary = TypeOf<typeof structureRiskScoresByBoundary>;
