import { ResultOf } from '@graphql-typed-document-node/core';
import type {
    DeepOmit,
    PrettyIntersect,
} from 'common';
// import { BusyResponseConfig, PerfResponseConfig, ResponsePlan } from 'config-schema';
import { type ModelInputParams } from 'deployment-model-service/browser';
import type {
    FeatureCollection,
    LineString,
    MultiLineString,
    MultiPolygon,
    Polygon,
} from 'geojson';
import * as t from 'io-ts';
import _ from 'lodash';
import {
    CreateScenarioDocument,
    CreateScenarioInput,
    DeleteScenarioDocument,
    GetAlternateRoadNetworksMetadataDocument,
    GetBaseMapBoundariesMetadataDocument,
    GetBoundaryDocument,
    GetBoundaryMetadataDocument,
    GetClosedRoadNetworkDocument,
    GetDemandMetadataDocument,
    GetDeploymentConfigDocument,
    GetDynamicStationZonesDocument,
    GetFutureRoadNetworkDocument,
    GetGeojsonDocument,
    GetGeolookupDocument,
    GetInitialMapBoundsDocument,
    GetMetricsDocument,
    GetModesDocument,
    GetNewResponseTypesDocument,
    GetNodesDocument,
    GetResponseTypesDocument,
    GetScenarioMetadataDocument,
    GetScenariosDocument,
    GetSurroundingAreaDocument,
    GetZoneTypeFiltersDocument,
    ListBoundariesDocument,
    ModelResultByNodeIdDocument,
    ModelResultByZoneTypeDocument,
    ModelResultClosestStationsDocument,
    ModelResultDocument,
    ModelResultForZoneTypeDocument,
    ModelResultSummaryDocument,
    ModelResultUnitPropertiesDocument,
    ModelRunStatusDocument,
    PrimeCacheDocument,
    RunModelDocument,
    RunModelFromScenarioDocument,
    ShareScenarioDocument,
    UnshareScenarioDocument,
    UpdateScenarioDocument,
    UpdateScenarioInput,
} from '@/generated/graphql-operations';
import { parse } from '@api-client/helpers/types';
import type { TenantApiClient } from '../../TenantApiClient';
import { StandardizedNulls, standardizeNulls } from '../../helpers';
import type { LayerStyling } from '../../types';
import { featureCollection, multiPolygonGeometry, polygonGeometry } from './types/geojson';
import { node, targetNode } from './types/node';

const SCALE_TYPES = [
    'constant',
    'linearRelative',
    'linearAbsolute',
    'threshold',
    'relativeThreshold',
    'divergingThreshold',
    'divergingRelative',
] as const;
export type ScaleType = typeof SCALE_TYPES[number];

export { type ModelInputParams };

type ApiModeConfig = StandardizedNulls<ResultOf<typeof GetModesDocument>['api']['modes'][number]>;
export type ModeConfig = PrettyIntersect<ApiModeConfig & {
    diffColorScaleType: ScaleType;
    diffColorScaleDomain: number[];
    diffColorScaleRange: string[];
    colorScaleType: ScaleType;
    sizeScaleType?: ScaleType;
    summaryMetric: MetricConfig;
    colorScaleMetric: MetricConfig;
    sizeScaleMetric?: MetricConfig;
}>;

const METRIC_IDS = ['performance', 'callVolume', 'overgoalCalls', 'workload', 'driveTime', 'driveTimeRelative'] as const;
export type MetricId = typeof METRIC_IDS[number];

const METRIC_AGGREGATE_BYS = ['sum', 'weightedAverage'] as const;
export type MetricAggregateBy = typeof METRIC_AGGREGATE_BYS[number];

const METRIC_FORMATTERS = ['number', 'time', 'minutes', 'hours'] as const;
export type MetricFormatter = typeof METRIC_FORMATTERS[number];

const METRIC_POSITIVE_DIRECTIONS = [1, -1] as const;
export type MetricPositiveDirection = typeof METRIC_POSITIVE_DIRECTIONS[number];

type ApiMetricConfig = StandardizedNulls<ResultOf<typeof GetMetricsDocument>['api']['metrics'][number]>;
export type MetricConfig = PrettyIntersect<ApiMetricConfig & {
    metricId: MetricId;
    property: MetricId;
    aggregateBy: MetricAggregateBy;
    formatter: MetricFormatter;
    positiveDirection: MetricPositiveDirection;
}>;

export type BoundaryMetadata = StandardizedNulls<ResultOf<typeof ListBoundariesDocument>['api']['boundaries'][number]>;
export type BoundaryProps = {
    id: number;
    name: string;
};
export type Boundary = PrettyIntersect<BoundaryMetadata & {
    geojson: FeatureCollection<MultiPolygon | Polygon, BoundaryProps>
}>;
export type BoundaryZoneMetadata = StandardizedNulls<ResultOf<typeof GetBoundaryMetadataDocument>['api']['boundaryZoneMetadata'][number]>;

export type BaseMapBoundariesMetadata = {
    aggLevelTimeframesMap: Record<string, string[]>;
    styleLayer?: LayerStyling;
};
export type AlternateRoadNetworksMetadata = {
    timeframeRoadScenariosMap: Record<string, string>;
    styleLayers: {
        future?: LayerStyling;
        closed?: LayerStyling;
    };
};
export type AlternateRoadType = 'future' | 'closed';

export type Config = PrettyIntersect<Omit<StandardizedNulls<ResultOf<typeof GetDeploymentConfigDocument>['api']>['config'], 'features'> & {
    features: Record<string, boolean>
}>;

export type DemandConfig = StandardizedNulls<ResultOf<typeof GetDemandMetadataDocument>['api']>;
export type DemandType = DemandConfig['demandTypes'][number];
export type DemandTimeframe = DemandConfig['demandTimeframes'][number];
export type DemandSegment = DemandConfig['demandSegments'][number];
export type CompositeDemandSegment = {
    kind: 'demandSegment' | 'demandSegmentCombination';
    demandSegmentId: string;
    label: string;
    includedDemandSegmentIds: string[];
};
export type DemandSegmentCombination = {
    demandSegmentId: string; // Duplicate of combination id
    label: string;
    demandSegmentIds: string[];
};
export type DemandSet = {
    demandTypeId: string;
    demandTimeframeId: string;
    demandSegmentId: string;
    demandType: DemandType;
    demandTimeframe: DemandTimeframe;
    demandSegment: DemandSegment;
};

export type Node = t.TypeOf<typeof node>;
export type TargetNode = t.TypeOf<typeof targetNode>;

// export type ApiResponseType = StandardizedNulls<ResultOf<typeof GetResponseTypesDocument>['api']['responseTypes'][number]>;
export type ResponseType = {
    __typename: 'ResponseType'
    responseTypeId: string;
    label: string;
    orderBy?: number | null;
    responderCount: number;
    responderType: string;
    responderTarget: {
        nodeType: string;
        target: number;
        targetTravel: number;
    }[];
};

export type ScenarioMetadata = StandardizedNulls<ResultOf<typeof GetScenarioMetadataDocument>['api']>;
export type ApparatusTypeConfig = ScenarioMetadata['apparatusTypeConfig'][number];
export type ZoneTypeFilter = StandardizedNulls<ResultOf<typeof GetZoneTypeFiltersDocument>['api']['zoneTypeFilters'][number]>;
export type TemplateStation = DeepOmit<ScenarioMetadata['templateStation'], '__typename'>;
export type TemplateUnit = Omit<ScenarioMetadata['templateUnits'][number], '__typename'>;

export type ScenarioConfig = StandardizedNulls<ResultOf<typeof GetScenariosDocument>['api']['scenarios'][number]>;
export type ScenarioContext = Pick<ScenarioConfig['context'], 'demandTypeId' | 'demandTimeframeId' | 'demandSegmentId' | 'target' | 'responsePlan' | 'boundaryId'>;
export type Station = ScenarioConfig['stations'][number];
export type Unit = Station['units'][number];

export type GeoLookupRow = ResultOf<typeof GetGeolookupDocument>['api']['geolookup'][number];

export type ModelRunStatus = NonNullable<StandardizedNulls<ResultOf<typeof ModelRunStatusDocument>['api']['modelRun']>>;
export type ModelResult = NonNullable<StandardizedNulls<ResultOf<typeof ModelResultSummaryDocument>['api']>['modelRun']>['resultSummary'];
export type ModelResultByZoneType = NonNullable<StandardizedNulls<ResultOf<typeof ModelResultByZoneTypeDocument>['api']>['modelRun']>['resultByZoneType'][number];
export type ModelResultByNodeId = NonNullable<StandardizedNulls<ResultOf<typeof ModelResultByNodeIdDocument>['api']>['modelRun']>['resultByNodeId'][number];
export type ModelResultUnitProperties = NonNullable<StandardizedNulls<ResultOf<typeof ModelResultUnitPropertiesDocument>['api']>['modelRun']>['unitProperties'][number];

// https://colorbrewer2.org/#type=diverging&scheme=RdYlGn&n=11
// But with the middle color changed from yellow to white
const DIVERGING_COLOR_PALETTE = [
    '#7A0012',
    '#A10F04',
    '#CD4C0B',
    '#F09242',
    '#F5D597',
    '#ffffff',
    '#A6E6AE',
    '#70C588',
    '#45A56F',
    '#23845D',
    '#0B634D',
];

function validateEnum<V extends string | number, E extends V>(enumValues: ReadonlyArray<E>, value: V): E {
    if (enumValues.includes(value as E)) {
        return value as E;
    }
    throw new Error(`${value} must be one of ${enumValues}`);
}

function camelCaseKeys(obj: any) {
    return _.mapKeys(obj, (value, key) => _.camelCase(key));
}

function camelCaseMetricProps(metric: ApiMetricConfig): MetricConfig {
    return {
        ...metric,
        positiveDirection: validateEnum(METRIC_POSITIVE_DIRECTIONS, metric.positiveDirection),
        formatter: validateEnum(METRIC_FORMATTERS, _.camelCase(metric.formatter)),
        metricId: validateEnum(METRIC_IDS, _.camelCase(metric.metricId)),
        property: validateEnum(METRIC_IDS, _.camelCase(metric.property)),
        aggregateBy: validateEnum(METRIC_AGGREGATE_BYS, _.camelCase(metric.aggregateBy)),
    };
}

function camelCaseModeMetricProps(mode: ApiModeConfig) {
    if (!(mode instanceof Object)) {
        return mode;
    }
    return {
        ...mode,
        colorScaleType: validateEnum(SCALE_TYPES, _.camelCase(mode.colorScaleType)),
        sizeScaleType: mode.sizeScaleType ? validateEnum(SCALE_TYPES, _.camelCase(mode.sizeScaleType)) : undefined,
        summaryMetric: mode.summaryMetric && camelCaseMetricProps(mode.summaryMetric),
        colorScaleMetric: mode.colorScaleMetric && camelCaseMetricProps(mode.colorScaleMetric),
        sizeScaleMetric: mode.sizeScaleMetric && camelCaseMetricProps(mode.sizeScaleMetric),
    };
}

function camelCaseModeMetricArrayProps(modes: any) {
    if (!(modes.length)) {
        return modes;
    }
    return modes.map(camelCaseModeMetricProps);
}

export type AggregationParams = {
    zoneType: string;
    nodeTypeFilter: string | null;
    zoneTypeFilters: Record<string, number[]>,
}

export class DeploymentApi {
    api: TenantApiClient;

    constructor(api: TenantApiClient) {
        this.api = api;
    }

    /**
     * Get the static geojson layers that are always visible
     */
    async getStaticGeojson(): Promise<{
        hex: Boundary['geojson'];
        serviceArea?: Boundary['geojson'];
    }> {
        const {
            hexGeojson,
            serviceAreaGeojson
        } = await this.api.query(GetGeojsonDocument, { tenantId: this.api.db }).then(result => result.api);
        if (!hexGeojson?.geojson) {
            throw new Error('Hex geojson layer is empty');
        }
        return {
            hex: parse(featureCollection, JSON.parse(hexGeojson.geojson)),
            serviceArea: serviceAreaGeojson
                ? parse(featureCollection, JSON.parse(serviceAreaGeojson.geojson))
                : undefined
        };
    }

    /**
     * Gets the list of all boundaries' metadata
     * Does not include the full geojson object
     */
    async getBoundariesMetadata(): Promise<BoundaryMetadata[]> {
        const { boundaries } = await this.api.query(ListBoundariesDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(boundaries);
    }

    /**
     * Gets a single boundary's metadata and geojson data.
     */
    async getBoundary(boundaryId: string): Promise<Boundary> {
        const { boundary } = standardizeNulls(await this.api.query(GetBoundaryDocument, {
            tenantId: this.api.db,
            boundary: boundaryId
        }).then(result => result.api));
        if (!boundary) {
            throw new Error(`No boundary found with ID: ${boundaryId}`);
        }

        return {
            ...boundary,
            // @ts-ignore
            geojson: parse(featureCollection, JSON.parse(boundary.geojson))
        };
    }

    /**
     * Gets all zones for all agg levels
     */
    async getBoundaryZones(): Promise<BoundaryZoneMetadata[]> {
        const { boundaryZoneMetadata } = await this.api.query(GetBoundaryMetadataDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(boundaryZoneMetadata);
    }

    /**
     * Gets miscellaneous app configuration
     */
    async getConfig(): Promise<Config> {
        const { config: { features, ...rest } } = standardizeNulls(
            await this.api.query(GetDeploymentConfigDocument, { tenantId: this.api.db }).then(result => result.api)
        );
        return {
            ...rest,
            features: parse(
                t.record(t.string, t.boolean),
                // @ts-ignore
                camelCaseKeys(JSON.parse(features))
            )
        };
    }

    /**
     * Gets all demand set metadata.
     * @returns An object with configuration of all demand set objects
     */
    async getDemandSetMetadata(): Promise<DemandConfig> {
        const data = await this.api.query(GetDemandMetadataDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(data);
    }

    /**
     * Get a dynamically calculated station zones geojson. Each feature is the
     * region closest to a particular station.
     * @param modelRunId The id of the model run to use in calculating zones
     * @returns A geojson feature collection representing dynamic station zones
     */
    async getDynamicStationZones(modelRunId: number): Promise<Boundary['geojson']> {
        const { modelRun } = await this.api.query(GetDynamicStationZonesDocument, {
            tenantId: this.api.db,
            id: modelRunId
        }).then(result => result.api);
        if (!modelRun) {
            throw new Error(`Model run ${modelRunId} not found`);
        }
        return parse(featureCollection, JSON.parse(modelRun.stationZones));
    }

    /**
     * Gets the full geolookup array. A geolookup record determines how to aggregate
     * node-based values into agg levels when a node or hex doesn't cleanly
     * fall entirely within one region.
     * @returns Array of geolookup records
     */
    async getGeolookup(): Promise<GeoLookupRow[]> {
        const { geolookup } = await this.api.query(GetGeolookupDocument, { tenantId: this.api.db }).then(result => result.api);
        return geolookup.map((row: GeoLookupRow) => ({
            ...row,
            proportion: +row.proportion,
            isNodeZone: !!row.isNodeZone
        }));
    }

    /**
     * Get the metric configuration for all metrics
     */
    async getMetrics(): Promise<MetricConfig[]> {
        const { metrics } = await this.api.query(GetMetricsDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(metrics).map((metric: ApiMetricConfig) => camelCaseMetricProps(metric));
    }

    /**
     * Get the mode configuration for all modes. A mode combines a primary metric
     * (what is shown in the sidebar) with additional references to secondary metrics
     * that control other things like hexbin sizing.
     */
    async getModes(): Promise<ModeConfig[]> {
        const { modes } = await this.api.query(GetModesDocument, {
            tenantId: this.api.db,
        }).then(result => result.api);

        return standardizeNulls(modes)
            .map((mode: ApiModeConfig) => ({
                ...camelCaseModeMetricProps(mode),
                diffColorScaleType: 'divergingRelative',
                diffColorScaleDomain: [-0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6, 0.8],
                diffColorScaleRange: (mode.modeId === 'performance')
                    // Default performance metric colors but with white as a middle color
                    ? DIVERGING_COLOR_PALETTE
                    : DIVERGING_COLOR_PALETTE.slice().reverse(),
            }));
    }

    /**
     * Get the list of nodes
     */
    async getNodes(): Promise<Node[]> {
        const { nodes } = await this.api.query(GetNodesDocument, { tenantId: this.api.db }).then(result => result.api);
        return parse(t.array(node), standardizeNulls(nodes));
    }

    /**
     * Get the list of response types and target groups
     */
    async getResponseTypes() {
        const r = await this.api.query(GetResponseTypesDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(r);
    }

    // /**
    //  * Get the new response type configs
    //  */
    // async getNewResponseTypes(): Promise<{
    //     responsePlans: Record<string, ResponsePlan>;
    //     perfResponseConfigs: Record<string, PerfResponseConfig>;
    //     busyResponseConfigs: Record<string, BusyResponseConfig>;
    // }> {
    //     const {
    //         responsePlans,
    //         perfResponseConfigs,
    //         busyResponseConfigs
    //     } = await this.api.query(GetNewResponseTypesDocument, { tenantId: this.api.db }).then(result => result.api);
    //     return {
    //         responsePlans: responsePlans ?? {},
    //         perfResponseConfigs: perfResponseConfigs ?? {},
    //         busyResponseConfigs: busyResponseConfigs ?? {}
    //     };
    // }

    /**
     * Gets all scenario-related metadata, not including the scenarios
     * themselves. This includes:
     *
     * * Staff, Station, and Apparatus type configurations
     * * Templates for stations/units that can be added
     * * Options for setup times to use
     */
    async getScenarioMetadata(): Promise<ScenarioMetadata> {
        const scenarioMetadata = await this.api.query(GetScenarioMetadataDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(scenarioMetadata);
    }

    /**
     * Gets all scenarios that the current user has access to.
     */
    async getScenarios(): Promise<ScenarioConfig[]> {
        const { scenarios } = await this.api.query(GetScenariosDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(scenarios);
    }

    /**
     * Get the geometry of the surrounding area for a specified agg level. If a feature ID is
     * provided, then the surrounding area will be for only the feature. Otherwise, the
     * surrounding area is computed for the union of all agg level features.
     */
    async getSurroundingArea(
        aggLevel: string,
        featureId?: number
    ): Promise<MultiPolygon | Polygon | undefined> {
        // These agg levels are either slow, or their geometries
        // can't be aggregated into a sensible surrounding area.
        // Just return undefined rather than attempting to calculate them
        const aggLevelIgnoreList = [
            'hex',
            'intersection'
        ];
        if (aggLevelIgnoreList.includes(aggLevel)) {
            return undefined;
        }

        const { surroundingArea } = await this.api.query(
            GetSurroundingAreaDocument,
            { tenantId: this.api.db, aggLevel, featureId }
        ).then(r => r.api);
        if (!surroundingArea) {
            throw new Error(
                `No surrounding area found for agg level ${aggLevel}, feature ID ${featureId}`
            );
        }
        return parse(
            t.union([multiPolygonGeometry, polygonGeometry]),
            standardizeNulls(surroundingArea)
        );
    }

    async getBaseMapBoundariesMetadata(): Promise<BaseMapBoundariesMetadata | undefined> {
        const { metadata } = await this.api.query(GetBaseMapBoundariesMetadataDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(metadata) as BaseMapBoundariesMetadata | undefined;
    }

    async getAlternateRoadNetworksMetadata(): Promise<AlternateRoadNetworksMetadata | undefined> {
        const { metadata } = await this.api.query(GetAlternateRoadNetworksMetadataDocument, { tenantId: this.api.db }).then(result => result.api);
        if (!metadata) {
            return undefined;
        }

        return standardizeNulls({
            timeframeRoadScenariosMap: _(metadata.timeframeRoadScenariosMap)
                .keyBy('timeframe')
                .mapValues('roadScenario')
                .value(),
            styleLayers: {
                future: metadata.styleLayers.future as LayerStyling | undefined,
                closed: metadata.styleLayers.closed as LayerStyling | undefined,
            },
        });
    }

    async getAlternateRoadNetwork(
        roadType: AlternateRoadType,
        roadScenario: string
    ): Promise<FeatureCollection<MultiLineString | LineString>> {
        const queryDocument = (roadType === 'future')
            ? GetFutureRoadNetworkDocument
            : GetClosedRoadNetworkDocument;
        const { geometry } = await this.api.query(queryDocument, {
            tenantId: this.api.db,
            roadScenario
        }).then(r => r.api);
        const features = (geometry)
            ? [{
                type: 'Feature' as const,
                geometry: JSON.parse(geometry) as MultiLineString | LineString,
                properties: null,
            }]
            : [];
        return {
            type: 'FeatureCollection',
            features,
        };
    }

    async getZoneTypeFilters() {
        const { zoneTypeFilters } = await this.api.query(GetZoneTypeFiltersDocument, { tenantId: this.api.db }).then(result => result.api);
        return standardizeNulls(zoneTypeFilters);
    }

    async getInitialMapBounds(): Promise<Polygon | undefined> {
        const { initialMapBounds } = await this.api.query(
            GetInitialMapBoundsDocument,
            {
                tenantId: this.api.db,
                aggLevelConfigKey: 'deployment_app.initial_map_bounds_agg_level'
            }
        ).then(r => r.api);
        return standardizeNulls(initialMapBounds) as Polygon | undefined;
    }

    /**
     * Ensures that the server's cache is populated with up-to-date data, which
     * will make subsequent queries faster.
     */
    async primeCache(): Promise<void> {
        await this.api.query(PrimeCacheDocument, { tenantId: this.api.db });
    }

    /**
     * Create a new scenario, saving it in the database and returning
     * its id.
     *
     * @param scenarioInput The scenario to be created
     * @returns The newly-created scenario's id
     */
    async createScenario(scenarioInput: StandardizedNulls<CreateScenarioInput>): Promise<number> {
        const { result: scenarioId } = await this.api.query(
            CreateScenarioDocument,
            { tenantId: this.api.db, input: scenarioInput }
        );
        if (!scenarioId) {
            throw new Error('Couldn\'t create scenario');
        }
        return scenarioId;
    }

    /**
     * Delete a scenario from the database
     *
     * @param scenarioId The scenario id to be deleted
     * @returns true if the deletion succeeds, false otherwise
     */
    async deleteScenario(scenarioId: number): Promise<boolean> {
        const { result } = await this.api.query(
            DeleteScenarioDocument,
            { tenantId: this.api.db, input: { scenarioId: parse(t.number, scenarioId) } }
        );
        return result;
    }

    /**
     * Shares a scenario, making it visible to other users
     *
     * @param scenarioId The scenario to be shared
     * @returns true if the operation succeeds, false otherwise
     */
    async shareScenario(scenarioId: number): Promise<boolean> {
        const { result } = await this.api.query(
            ShareScenarioDocument,
            { tenantId: this.api.db, input: { scenarioId: parse(t.number, scenarioId) } }
        );
        return result;
    }

    /**
     * Unshares a scenario, hiding it from other users
     *
     * @param scenarioId The scenario to be unshared
     * @returns true if the operation succeeds, false otherwise
     */
    async unshareScenario(scenarioId: number): Promise<boolean> {
        const { result } = await this.api.query(
            UnshareScenarioDocument,
            { tenantId: this.api.db, input: { scenarioId: parse(t.number, scenarioId) } }
        );
        return result;
    }

    /**
     * Modifies a scenario by overwriting some of its properties
     *
     * @param scenarioId The scenario to be updated
     * @returns true if the operation succeeds, false otherwise
     */
    async updateScenario(
        scenarioId: number,
        newProps: Partial<StandardizedNulls<UpdateScenarioInput>>
    ): Promise<boolean> {
        const input = {
            scenarioId,
            ...newProps
        };
        const { result } = await this.api.query(UpdateScenarioDocument, {
            tenantId: this.api.db,
            input
        });
        return result;
    }

    /**
     * Return the status of a model run
     *
     * @param id The id of the model run to aggregate
     * @returns ModelRunStatus object
     */
    async getModelRunStatus(id: number): Promise<ModelRunStatus> {
        const { modelRun } = standardizeNulls(await this.api.query(ModelRunStatusDocument, {
            tenantId: this.api.db,
            id
        }).then(r => r.api));
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return modelRun;
    }

    /**
     * Return a system-wide summary of a model result. Optionally, if a zoneType
     * is provided, only include incidents that occur within at least one zone.
     * This can slightly change the results, but guarantees that resultsByZoneType
     * will add up to this summary value correctly.
     *
     * @param id The id of the model run to aggregate
     * @param zoneType Zone type to aggregate based on
     * @returns ModelResult object
     */
    async getModelResultSummary(
        id: number,
        zoneType?: string,
        zoneTypeId?: number
    ): Promise<ModelResult> {
        const { modelRun } = standardizeNulls(
            await this.api.query(ModelResultSummaryDocument, {
                tenantId: this.api.db,
                id,
                zoneType,
                zoneTypeId
            }).then(r => r.api)
        );
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return modelRun.resultSummary;
    }

    /**
     * Aggregate a model result by zone.
     *
     * @param id The id of the model run to aggregate
     * @param zoneType The zone type to aggregate by
     * @returns An array of ModelResultByZoneType objects, one per zone
     */
    async getModelResultByZoneType(
        id: number,
        zoneType: string
    ): Promise<ModelResultByZoneType[]> {
        const { modelRun } = standardizeNulls(
            await this.api.query(ModelResultByZoneTypeDocument, {
                tenantId: this.api.db,
                id,
                zoneType
            }).then(r => r.api)
        );
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return modelRun.resultByZoneType;
    }

    /**
     * Returns the model result for each nodeId. This is the most
     * granular level a model result exists at.
     *
     * @param id The model result to return
     * @returns An array of ModelResultByNodeId objects, one per node id
     */
    async getModelResultByNodeId(
        id: number,
        zoneType?: string,
        zoneTypeId?: number
    ): Promise<ModelResultByNodeId[]> {
        const { modelRun } = standardizeNulls(
            await this.api.query(ModelResultByNodeIdDocument, {
                tenantId: this.api.db,
                id,
                zoneType,
                zoneTypeId
            }).then(r => r.api)
        );
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return modelRun.resultByNodeId;
    }

    /**
     * Returns an object that can be used to look up the closest station to
     * each nodeId.
     *
     * @param id The model result to use in calculating
     * @returns Object keyed on nodeId and valued on nearest station nodeId
     */
    async getModelResultClosestStations(id: number): Promise<Record<string, string>> {
        const { modelRun } = standardizeNulls(
            await this.api.query(ModelResultClosestStationsDocument, {
                tenantId: this.api.db,
                id
            }).then(r => r.api)
        );
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return _(modelRun.closestStations)
            .keyBy(r => r.nodeId)
            .mapValues(r => r.stationNodeId)
            .value();
    }

    /**
     * Returns unit-specific properties from a model run, such as busy fraction
     * or setup time.
     *
     * @param id The model result to look up
     * @returns A lookup of demandSegmentId -> unitId -> unit properties
     */
    async getModelResultUnitProperties(
        id: number
    ): Promise<Record<string, Record<string, ModelResultUnitProperties>>> {
        const { modelRun } = standardizeNulls(
            await this.api.query(ModelResultUnitPropertiesDocument, {
                tenantId: this.api.db,
                id
            }).then(r => r.api)
        );
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        return _(modelRun.unitProperties)
            .groupBy(p => p.demandSegmentId)
            .mapValues(demandSetRows => _(demandSetRows)
                .keyBy(row => row.unitId)
                .value())
            .value();
    }

    /**
     * Returns all model result properties that don't require a zoneType
     * to aggregate.
     *
     * @param id The model result to look up
     * @returns Object containing model result properties
     */
    async getModelResult(id: number): Promise<{
        unitProperties: Record<string, Record<string, ModelResultUnitProperties>>;
        closestStations: Record<string, string>;
        resultByNodeId: ModelResultByNodeId[];
    }> {
        const { modelRun } = standardizeNulls(await this.api.query(ModelResultDocument, {
            tenantId: this.api.db,
            id
        }).then(r => r.api));
        if (!modelRun) {
            throw new Error(`Model run ${id} not found`);
        }
        const unitProperties = _(modelRun.unitProperties)
            .groupBy(p => p.demandSegmentId)
            .mapValues(demandSetRows => _(demandSetRows)
                .keyBy(row => row.unitId)
                .value())
            .value();

        const closestStations = _(modelRun.closestStations)
            .keyBy(r => r.nodeId)
            .mapValues(r => r.stationNodeId)
            .value();

        const resultByNodeId = modelRun.resultByNodeId;

        return {
            unitProperties,
            closestStations,
            resultByNodeId
        };
    }

    /**
     * Returns a model result with all its properties aggregated.
     *
     * @param id The model result to look up
     * @param aggregationParams The parameters to aggregate by
     * @returns An object with aggregated model properties
     */
    async getAggregatedModelResult(id: number, aggregationParams: AggregationParams): Promise<{
        resultByZoneType: ModelResultByZoneType[];
        resultSummary: ModelResult;
    }> {
        try {
            const { modelRun } = standardizeNulls(
                await this.api.query(ModelResultForZoneTypeDocument, {
                    tenantId: this.api.db,
                    id,
                    zoneType: aggregationParams.zoneType,
                    nodeTypeFilter: aggregationParams.nodeTypeFilter,
                    zoneTypeFilters: Object.entries(aggregationParams.zoneTypeFilters ?? {})
                        .map(([zoneType, zoneTypeIds]) => ({ zoneType, zoneTypeIds })),
                }).then(r => r.api)
            );

            if (!modelRun) {
                throw new Error(`Model run ${id} not found`);
            }

            return {
                resultByZoneType: modelRun.resultByZoneType,
                resultSummary: modelRun.resultSummary
            };
        } catch (e) {
            throw new Error(`Threw attempting to query aggregatedModelResult for model run ${id}`, { cause: e });
        }
    }

    /**
     * Gets a model run id from a scenario. Will create it if it doesn't exist,
     * or return an existing model run if there's an exact match on inputs.
     * Uses the saved scenario context and stations to run the model.
     *
     * @param scenarioId The id of a scenario to use as input
     * @returns A model run's id
     */
    async getModelRunIdFromScenarioId(scenarioId: number): Promise<number> {
        const { id } = await this.api.query(RunModelFromScenarioDocument, {
            tenantId: this.api.db,
            scenarioId
        });
        return parse(t.number, id);
    }

    /**
     * Gets a model run from a full model input. Will create a new model run
     * if it doesn't exist, or return an existing model run if there's an exact
     * match.
     *
     * @param input Full input JSON needed to run the model
     * @returns A model run's id
     */
    async getModelRunId(input: ModelInputParams): Promise<number> {
        const { id } = await this.api.query(RunModelDocument, {
            tenantId: this.api.db,
            input: JSON.stringify(input)
        });
        return parse(t.number, id);
    }

    /**
     * Wait for a model run to complete. Use this if you don't care about
     * displaying progress to the user, just guaranteeing that the model run
     * is complete.
     *
     * @param id The model run to wait for
     * @param pollFallback If true, when we encounter a server error, fall back on polling the server
     * @returns A promise that resolves when the model run is complete
     */
    async waitForModelRun(id: number, pollFallback = true): Promise<void> {
        if (pollFallback) {
            return this.pollModelRunUntilComplete(id);
        }
        throw new Error('Websockets are disabled');
    }

    /**
     * Wait for a model run to complete by repeatedly polling it. Use this if
     * you want to use waitForModelRun, but the server won't accept subscriptions
     *
     * This is just a temporary fallback for a server problem. Ideally,
     * we should use subscriptions all of the time. However, postgraphile doesn't
     * officially support connecting to multiple databases, and, as a consequence,
     * doesn't handle multiple websocket connections properly. So, until we have
     * a better, more stable way to handle websockets, we use polling instead
     *
     * @param id The model run to wait for
     * @param options.pollInterval The interval to wait between requests (in ms)
     * @param options.retries Number of times to retry
     * @returns A promise that resolves when the model run is complete
     */
    async pollModelRunUntilComplete(
        id: number,
        options?: {
            pollInterval?: number;
            retries?: number;
            onProgress?: (progress: number) => void;
        }
    ): Promise<void> {
        const retries = options?.retries ?? 20;
        const pollInterval = options?.pollInterval ?? 3000;
        const onProgress = options?.onProgress;

        const status = await this.getModelRunStatus(id);

        if (onProgress) {
            onProgress(status.progress);
        }

        if (status.error) {
            throw new Error(status.error);
        }

        if (status.complete) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            setTimeout(() => this.pollModelRunUntilComplete(
                id,
                { pollInterval, retries: retries - 1, onProgress }
            ).then(resolve).catch(reject), pollInterval);
        });
    }
}
