import type { RouteRecordRaw } from 'vue-router';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { daAuthClient, tracker } from '@api-client';

const Sidebar = () => import('@/views/Sidebar');
const EmptyContainer = () => import('@/views/EmptyContainer.vue');

const AppPickerPage = () => import('@/pages/AppPicker');
const TenantPickerPage = () => import('@/pages/TenantPicker');
const DiagnosticsAppComponent = () => import('@/pages/Diagnostics/DiagnosticsApp.vue');

export const route: RouteRecordRaw = {
    path: '/diagnostics',
    components: {
        sidebar: Sidebar,
        default: EmptyContainer
    },
    props: {
        sidebar: {
            expandedByDefault: false,
            app: 'Diagnostics'
        }
    },
    children: [{
        name: 'logout',
        path: 'logout',
        redirect: () => {
            daAuthClient.logout();
            return '/diagnostics/login';
        }
    }, {
        path: '',
        component: DiagnosticsAppComponent,
        children: [{
            path: '',
            name: 'DiagnosticsDatabasePicker',
            component: TenantPickerPage,
            props: {
                title: 'Diagnostics',
                app: 'Diagnostics'
            },
            beforeEnter: redirectToValidRouteForUser({ appName: 'Diagnostics' })
        }, {
            // Any route that doesn't start with /login. Ideally, we'd match everything but _exactly_
            // login, but Vue wraps this regex in some of its own syntax which breaks a naive "everything but
            // login" regex.
            path: ':tenant((?!login\\)[^/]+)',
            name: 'DiagnosticsPicker',
            component: AppPickerPage,
            props: {
                title: 'Diagnostics',
                appName: 'Diagnostics',
                onSelectedRoute: 'DiagnosticsApp',
                appRouteParameter: 'diagnostics'
            },
            beforeEnter: redirectToValidRouteForUser({ appName: 'Diagnostics' })
        }, {
            path: ':tenant((?!login\\)[^/]+)/:diagnostics',
            name: 'DiagnosticsApp',
            component: () => import('@diagnostics/views/PerfDiag/PerfDiag.vue'),
            beforeEnter(to, from, next) {
                tracker.registerSessionProps({
                    database: to.params.tenant,
                    diagnosticsId: to.params.diagnostics,
                });
                next();
            },
        }]
    }, {
        path: ':pathMatch(.*)*',
        redirect: '/',
    }],
    meta: { appName: 'Diagnostics' }
};
