import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useTenantId } from '@/composables/useTenantId';
import { useTenantListStore } from '../TenantListStore/TenantListStore';

export const useTenantInfoStore = defineStore('TenantInfoStore', () => {
    const tenantListStore = useTenantListStore();

    const tenantId = useTenantId();
    const tenantInfo = computed(() => {
        if (!tenantId.value) {
            return undefined;
        }
        return tenantListStore.getTenantInfo(tenantId.value);
    });

    const isLoading = ref(true);
    const hasLoaded = ref(false);

    watch(tenantInfo, t => {
        if (t) {
            isLoading.value = false;
            hasLoaded.value = true;
        }
    }, { immediate: true, flush: 'sync' });

    return {
        tenantInfo,
        isLoading,
        hasLoaded,
    };
});
