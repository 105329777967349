import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import AsyncEventEmitter from 'async-eventemitter';
import Cookies from 'js-cookie';
import type { App, Component } from 'vue';

/* eslint-disable no-param-reassign, @typescript-eslint/no-this-alias */

/*
 This auth client is a wrapper for the cognito API.
 Promisifies the Cognito API for use wiuth async/await.
 */
import { Router } from 'vue-router';
import { AuthRouterVue } from './auth-router';
import {
    authenticateUser as authenticateCognitoUser,
    confirmPassword as cognitoConfirmPassword,
    forgotPassword as cognitoForgotPassword,
    completeNewPasswordChallenge,
    getSession as getCognitoSession,
    getUser as getCognitoUser,
    getUserAttributes as getCognitoUserAttributes,
    getCurrentUser as getCurrentCognitoUser,
    getUserFromTokens
} from './cognito';
import { fetchTokensAndUserInfo, getAuthorizationCodeFromUrl } from './oauth';

// Change routing implementation set in .use on setup
let _loginHandler: undefined | (() => void);

class AuthChallenge extends Error {
    code: any;
    challenge: any;
    prompt: any;
    response: any;
    props: any;

    constructor({ message = 'Auth Challenge', code = -1, challenge, prompt, response, props }: any) {
        super(message);
        this.code = code;
        this.challenge = challenge;
        this.prompt = prompt;
        this.response = response;
        this.props = props;
    }
}

class AuthenticationError extends Error {
    status: number;
    constructor(e: ConstructorParameters<ErrorConstructor>[0]) {
        super(e);
        this.status = 401;
    }
}

export class User {
    private cognitoUserAttributes?: CognitoUserAttribute[];
    cognitoUser?: CognitoUser;
    cognitoSession?: CognitoUserSession;
    sentCodeTo: any;

    private constructor(cognitoUser?: CognitoUser) {
        this.cognitoUser = cognitoUser;
    }

    // Default factory method
    static async create() {
        const code = getAuthorizationCodeFromUrl();
        if (code) {
            const {
                idToken,
                accessToken,
                refreshToken,
                userInfo
            } = await fetchTokensAndUserInfo(code);

            const cognitoUser = getUserFromTokens(
                userInfo.username,
                idToken,
                accessToken,
                refreshToken
            );
            return new User(cognitoUser);
        }
        const cognitoUser = getCurrentCognitoUser();
        return new User(cognitoUser ?? undefined);
    }
    // Used by login, and any other functions that don't first need a session,
    // just a username (password reset, MFA, confirmation codes, etc)
    // DOES NOT set the current user, that is set in the functions that use this one.
    static async createFromUsername(username: string) {
        const cognitoUser = getCognitoUser(username.toLowerCase());
        return new User(cognitoUser);
    }

    get isAuthenticated() {
        return !!this.auth;
    }

    async sendResetCode() {
        if (!this.cognitoUser) {
            throw new Error('Can\'t send reset code. cognitoUser is undefined');
        }
        const { CodeDeliveryDetails: { Destination: sentCodeTo } } = await cognitoForgotPassword(this.cognitoUser);
        this.sentCodeTo = sentCodeTo;
        return this;
    }

    async confirmPassword({ resetCode, password }: { resetCode: string; password: string }) {
        if (!this.cognitoUser) {
            throw new Error('Can\'t confirm password. cognitoUser is undefined');
        }
        return cognitoConfirmPassword(this.cognitoUser, { resetCode, password });
    }

    async authenticate({ password, remember }: { password: string; remember?: boolean }) {
        try {
            if (!this.cognitoUser) {
                throw new Error('Can\'t authenticate. cognitoUser is undefined');
            }
            const session = await authenticateCognitoUser(this.cognitoUser, { password, remember });
            await this.setUserSession(session);
            return this;
        } catch (err) {
            this.throwAuthError(err);
        }
        return this;
    }

    async setUserSession(cognitoSession: CognitoUserSession) {
        this.cognitoSession = cognitoSession;
        await this.updateUserSession();
    }

    async refresh() {
        if (this.cognitoUser) {
            try {
                const session = await getCognitoSession(this.cognitoUser);
                await this.setUserSession(session);
                return this;
            } catch (e) {
                // This fails silently.  .freshToken will throw an error and redirect.
                console.info(`Failed to refresh user ${this.username}`, (e as any).message);
            }
        }
        return this;
    }

    async updateUserSession() {
        if (!this.cognitoUser) {
            throw new Error('Can\'t get user attributes. cognitoUser is undefined');
        }
        this.cognitoUserAttributes = await getCognitoUserAttributes(this.cognitoUser);
        return this;
    }

    get userAttributes() {
        return this.cognitoUserAttributes ?? [];
    }

    get idToken() {
        return this.cognitoSession && this.cognitoSession.getIdToken();
    }
    get isExternalUser() {
        return this.idToken && this.idToken.payload.identities?.length > 0;
    }
    get username() {
        return this.cognitoUser?.getUsername() ?? 'user not logged in';
    }

    get groups() {
        return this.idToken && this.idToken.payload['cognito:groups'];
    }

    get email() {
        return (this.idToken && this.idToken.payload.email) ?? '';
    }

    get name() {
        return this.idToken && this.idToken.payload.given_name || this.username;
    }

    get displayName() {
        return (this.isExternalUser)
            ? this.email
            : this.username;
    }

    get admin() {
        return this.groups && this.groups.indexOf('org:darkhorse') !== -1;
    }

    /**
     * Convenience to retrieve any auth tokens that may be needed from the user session
     * If isValid is false, then must await user.freshAuth instead to use tokens
     */
    get auth() {
        if (!this.cognitoSession) {
            return undefined;
        }

        const accessToken = this.cognitoSession.getAccessToken();
        const idToken = this.cognitoSession.getIdToken();

        const bearer = `Bearer ${accessToken.getJwtToken()}`;
        const headersAccess = { Authorization: bearer };
        const expires = new Date(accessToken.getExpiration() * 1000);

        const bearerID = `Bearer ${idToken.getJwtToken()}`;
        const headersID = { 'X-DHA-ID-Token': bearerID };

        const headers = {
            ...headersID,
            ...headersAccess
        };

        // Store a reference so isValid() refers to the same session as the
        // rest of the returned values
        const cognitoSession = this.cognitoSession;
        return {
            isValid: () => cognitoSession.isValid(),
            accessToken,
            headers,
            headersAccess,
            bearer,
            expires,
            idToken,
            bearerID,
            headersID
        };
    }

    // Async, must await this property when using.
    // Returns auth with valid tokens, refreshed if necessary.
    get freshAuth() {
        return (async () => {
            if (!this.auth || !this.auth.isValid()) {
                await this.refresh();
            }
            if (!this.auth || !this.auth.isValid()) {
                showLogin();
                return undefined;
            }
            return this.auth;
        })();
    }

    logout() {
        this.cognitoUser && this.cognitoUser.signOut();
        this.cognitoSession = undefined;
    }

    throwAuthError(err: any) {
        const user = this.cognitoUser;
        if (!user) {
            throw err;
        }
        const { challenge } = err;
        if (challenge) {
            if (challenge.newPasswordRequired) {
                throw new AuthChallenge({
                    ...err,
                    props: { resetCode: false, password: true, verify: true },
                    prompt: `Please enter a new password for ${this.username}`,
                    response: async ({ password, verify }: { password: string; verify: string }) => {
                        verifyMatch({ password, verify });
                        await completeNewPasswordChallenge(
                            user,
                            { password, challengeAttributes: challenge.newPasswordRequired }
                        );
                        return authClientSingleton.login({ username: user.getUsername(), password });
                    }
                });
            }
        } else if (err.code === 'PasswordResetRequiredException') {
            err.challenge = {
                passwordResetRequired: { username: this.username }
            };
            throw new AuthChallenge({
                ...err,
                props: { resetCode: true, password: true, verify: true },
                prompt: `Password Reset Required for ${this.username}`,
                response: async ({ password, verify, resetCode }: { password: string; verify: string; resetCode: string }) => {
                    verifyMatch({ password, verify });
                    await this.confirmPassword({ password, resetCode });
                    return authClientSingleton.login({ username: user.getUsername(), password });
                }
            });
        }
        throw err;
    }
}

async function login({ username, password, remember }: { username: string; password: string; remember?: boolean }) {
    return User.createFromUsername(username).then(u => u.authenticate({ password, remember }));
}

/**
 * Invokes the loginHandler to get user credentials if one is specified
 */
async function showLogin() {
    if (_loginHandler) {
        return _loginHandler();
    }
    throw new AuthenticationError('User Authentication Required');
}

// Returns a change password challenge for user's to change password.
async function passwordChallenge({ username }: { username: string }, sendCode: boolean) {
    try {
        let user = await User.createFromUsername(username);
        const challenge = { passwordReset: user };
        let prompt;
        if (sendCode) {
            user = await user.sendResetCode();
            prompt = 'A Reset Code has been sent to your email';
        } else {
            prompt = 'Please enter a Reset Code and change your password';
            try {
                // Fail attempted authenticate to get a user that can confirmPassword with a code.
                user = await user.authenticate({ password: 'none' });
            } catch (e) {
                if ((e as any).code !== 'NotAuthorizedException' && (e as any).code !== 'PasswordResetRequiredException') {
                    throw e;
                }
            }
        }
        return new AuthChallenge({
            props: { resetCode: true, password: true, verify: true },
            prompt,
            challenge,
            async response(props: any) {
                verifyMatch(props);
                return user.confirmPassword(props);
            }
        });
    } catch (e) {
        if ((e as any).code === 'InvalidParameterException') {
            throw new Error('Please enter your Username');
        }
        console.warn('passwordChallenge Error', e);
        throw e;
    }
}

class AuthClient {
    private _currentUser?: Promise<User>;
    evt: AsyncEventEmitter<any> = new AsyncEventEmitter();
    redirect?: (() => Promise<void>);
    authRouter?: AuthRouterVue;

    get currentUser(): Promise<User> {
        if (!this._currentUser) {
            this._currentUser = User.create();
        }
        return this._currentUser;
    }
    set currentUser(u: Promise<User>) {
        this._currentUser = u;
    }

    on(...args: Parameters<AsyncEventEmitter<any>['on']>) {
        return this.evt.on(...args);
    }
    async login({ username, password, remember }: { username: string; password: string; remember?: boolean }) {
        this.currentUser = login({ username, password, remember });
        // Await so the user is resolved by the time we try to redirect
        await this.currentUser;
        if (this.redirect) {
            return this.redirect();
        }
        this.evt.emit('login');
        return this.currentUser;
    }
    async logout() {
        this.evt.emit('logout');
        // If a server side cookie was set, it has a logout URL cookie to go
        // with it that is called to delete the cookie.
        const logoutUrl = Cookies.get('logout');
        if (logoutUrl) {
            await fetch(logoutUrl, {
                method: 'GET',
                credentials: 'include'
            });
        }
        return this.currentUser.then(u => {
            u.logout();
            this.currentUser = User.create();
            this.evt.emit('loggedout');
            return this.currentUser;
        });
    }
    install(
        Vue: App,
        {
            router,
            loginComponent,
            defaultPath
        }: {
            router: Router,
            loginComponent?: Component,
            defaultPath?: string
        }
    ) {
        if (!this.authRouter) {
            this.authRouter = new AuthRouterVue({ authClient: this, router, defaultPath });
        }

        // Only mount the login functionality if loginComponent is provided
        if (loginComponent) {
            this.setupAuthForPath('/', loginComponent);
        }
    }

    setupAuthForPath(path: string, loginComponent: Component) {
        if (!this.authRouter) {
            // eslint-disable-next-line max-len
            throw new Error('Can\'t mount login functionality without installing. Please ensure `vueApp.use(daAuthClient, { router })` is called before daAuthClient.mountLoginAt');
        }
        const appRouter = this.authRouter.mountAt(path, loginComponent);
        this.authRouter.vueRouter.addRoute(appRouter.loginRoute);
    }

    use({ loginHandler }: { loginHandler: () => void }) {
        use({ loginHandler });
    }

    passwordChallenge({ username }: { username: string }, sendCode: boolean) {
        passwordChallenge({ username }, sendCode);
    }

    showLogin() {
        showLogin();
    }

    loginHandler?: () => void;
}

const authClientSingleton = new AuthClient();

function use({ loginHandler }: { loginHandler: () => void }) {
    _loginHandler = loginHandler;
    AuthClient.prototype.loginHandler = loginHandler;
    return AuthClient;
}

function verifyMatch({ password, verify }: { password: string; verify: string }) {
    const match = (password === verify);
    if (!match) {
        throw new Error('Passwords do not match, please try again.');
    }
}

export { showLogin, use, passwordChallenge, AuthChallenge, AuthClient, authClientSingleton };
