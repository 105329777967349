import '@/assets/tailwind.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';

// Import api-client before any other JS to ensure
// feature toggles are properly initialized.
// See: projects/api-client/src/init.ts
import * as d3 from 'd3';
import * as luxon from 'luxon';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { SentryPlugin, daAuthClient } from '@api-client';
import filters from '@dashboard/filters';
import { setupGlobals } from '../deployment/src/globals';
import App from './App.vue';
import LoginPage from './pages/Login';
import router from './router';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        dev: boolean;
        prod: boolean;
        confirm(message?: string): Promise<boolean>;
        setConfirmHandler(handler: (message?: string) => Promise<boolean>): void;
        $filters: Record<string, any>;
    }
}

const pinia = createPinia();
const vueApp = createApp(App);
setupGlobals(vueApp);

// In the absence of fallback routes specified elsewhere in our routing
// logic, fallback to something useful.
vueApp.use(daAuthClient, { router, defaultPath: '/hq' });
vueApp.use(pinia);

vueApp.config.globalProperties.$filters = filters;

daAuthClient.setupAuthForPath('/hq', LoginPage);
daAuthClient.setupAuthForPath('/deployment', LoginPage);
daAuthClient.setupAuthForPath('/diagnostics', LoginPage);
daAuthClient.setupAuthForPath('/dashboard', LoginPage);
daAuthClient.setupAuthForPath('/risk', LoginPage);

vueApp.use(router);
vueApp.use(SentryPlugin, {
    ignoreErrors: [
        // Safe to ignore according to: https://stackoverflow.com/a/50387233
        'ResizeObserver loop limit exceeded'
    ],
});

vueApp.mount('#app');

// eslint-disable-next-line
(window as any).vueApp = vueApp;
(window as any).daAuthClient = daAuthClient;
(window as any).d3 = d3;
(window as any).luxon = luxon;
