import * as t from 'io-ts';
import _ from 'lodash';
import { GetDashboardConfigDocument, ListDashboardsDocument } from '@/generated/graphql-operations';
import { parse } from '@api-client/helpers/types';
import type { TenantApiClient } from '../../TenantApiClient';
import { timestampToFakeUTCDate } from './helpers';
import { groupedPerfdiagValueQuery } from './queries/getGroupedPerfdiagValues';
import { perfdiagValueQuery } from './queries/getPerfdiagValue';
import { DashboardConfig, dashboardConfig } from './types/config';
import { ListDashboardsResult, listDashboardsResult } from './types/listDashboards';

export class DashboardApi {
    api: TenantApiClient;
    constructor(api: TenantApiClient) {
        this.api = api;
    }
    async listDashboards(): Promise<ListDashboardsResult> {
        const result = await this.api.query(ListDashboardsDocument, { tenantId: this.api.db }).then(r => r.api);

        return parse(listDashboardsResult, result);
    }

    async getConfig(dashboardId: string, tsOptions: Intl.DateTimeFormatOptions): Promise<DashboardConfig> {
        const result = await this.api.query(GetDashboardConfigDocument, { tenantId: this.api.db, dashboardId });
        if (!result.api.DashboardsAPI) {
            throw new Error('Dashboards API not available');
        }
        const json = JSON.parse(result.api.DashboardsAPI.config);
        return parse(dashboardConfig, {
            ...json,
            mostRecentDate: timestampToFakeUTCDate(json.mostRecentDate, tsOptions),
            metrics: _.keyBy(json.metrics, m => m.key)
        });
    }

    async getPerfdiagValue(filters: any[], property: string): Promise<number> {
        const result = await this.api.query(perfdiagValueQuery, { tenantId: this.api.db, filters, property });

        if (!result.api.perfdiags) {
            console.warn('No Data for Query', filters, property);
            throw new Error('No Data for Query');
        }

        return parse(t.number, result.api.perfdiags.value);
    }

    async getGroupedPerfdiagValues(filters: any[], grouping: string, property: string): Promise<Record<string, number | null>> {
        const result = await this.api.query(groupedPerfdiagValueQuery, {
            tenantId: this.api.db,
            property,
            grouping,
            filters
        });

        if (!result.api.DashboardsAPI.perfdiagGroups) {
            console.warn('No Data for Query', filters, property);
            throw new Error('No Data for Query');
        }

        return parse(
            t.record(t.string, t.union([t.number, t.null])),
            _(result.api.DashboardsAPI.perfdiagGroups)
                .keyBy('key')
                .mapValues(obj => obj.value)
                .value()
        );
    }
}
