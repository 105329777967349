import { until } from '@vueuse/core';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { useTenantInfoStore } from '@/stores/TenantInfoStore';
import { route as dashboardRoute } from './dashboard';
import { route as deploymentRoute } from './deployment';
import { route as diagnosticsRoute } from './diagnostics';
import { route as hqRoute } from './hq';
import { route as riskRoute } from './risk';

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            redirect: '/hq',
            beforeEnter: redirectToValidRouteForUser({ appName: 'Home', allowOtherApps: true })
        },
        hqRoute,
        deploymentRoute,
        diagnosticsRoute,
        dashboardRoute,
        riskRoute
    ],
    strict: false
});

// Adapted from the approach described below:
// https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
router.afterEach(async (to, from) => {
    // Wait for navigation to settle before changing the title
    await nextTick();

    // Match the most-specific route that has a meta.title
    // This is so we can have an app-level title (HQ),
    // but a page within that app can override (HQ Settings, for example)
    const appName = _.findLast(to.matched, r => !!r.meta.appName)?.meta.appName as string | undefined;

    // !!! VERY IMPORTANT NOTE !!!: The useTenantId composable depends on the current route, so
    // if we use it in a router hook, we can easily create a circular dependency. This can completely
    // brick the app, because a broken router hook can prevent the router from mounting _any_ components.
    // This is why we reference to.params.tenant directly, even though we have a composable that
    // should do this for us.
    const tenantId = to.params.tenant as string | undefined;

    if (appName) {
        if (tenantId) {
            // We need to ensure that the tenantId is defined before we use the tenantInfoStore.
            // If the tenantId is undefined, tenantInfo will forever be undefined, so waiting for it
            // will cause this hook to hang indefinitely. But if the tenantId _is_ defined, tenantInfo
            // may still be loading, so in this case we do need to wait for it to be defined.
            const { tenantInfo } = storeToRefs(useTenantInfoStore());
            await until(tenantInfo).not.toBeUndefined();
            const tenantName = tenantInfo.value?.label;
            document.title = `${appName} | ${tenantName}`;
        } else {
            document.title = appName;
        }
    } else {
        document.title = 'darkhorse.app';
    }
});

(window as any).router = router;

export default router;
