import * as t from 'io-ts';
import { createDynamicQuery, createParseFunction } from '@api-client/helpers/types';

const groupedPerfdiagValueResult = t.type({
    api: t.type({
        DashboardsAPI: t.type({
            perfdiagGroups: t.union([t.null, t.array(t.type({
                key: t.string,
                value: t.union([t.number, t.null])
            }))])
        })
    })
});

export const groupedPerfdiagValueQuery = createDynamicQuery(
    'getGroupedPerfdiagValues',
    (name, variables?: {
        tenantId: string,
        filters: any[],
        grouping: string,
        property: string
    }) => {
        const {
            tenantId,
            property,
            filters,
            grouping
        } = variables!;
        const query = `
            query ${name} ($tenantId: String! $grouping: String!, $filters: JSON) {
                api: getTenantApi(name:$tenantId) {
                    DashboardsAPI {
                        perfdiagGroups(
                            grouping: $grouping
                            filters: $filters
                        ) {
                            key
                            ${property === 'totalCount'
        ? `value: ${property}`
        : `value: pctile(column:"${property}")`}
                        }
                    }
                }
        }`;

        const queryVariables = {
            tenantId,
            filters: JSON.stringify(filters),
            grouping
        };

        return {
            query,
            queryVariables
        };
    },
    createParseFunction(groupedPerfdiagValueResult)
);
