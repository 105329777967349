import type { HqConfig } from '../client';

type ValidatedConfig = {
    config: HqConfig,
    errors: string[];
};

/**
 * Check the provided HqConfig object for errors. Collect all errors into a user-friendly error
 * message and remove all erroneous entries from the config.
 */
export function validateConfig(config: HqConfig): ValidatedConfig {
    const configuredMetricIds = config.metrics.map((metric) => metric.id);
    const configuredMetricCards = config.metricCards.map((card) => card.id);
    const metricIdsWithUnderscores: string[] = [];
    const missingMetricIdErrors: string[] = [];
    const metricCardErrors: string[] = [];

    configuredMetricIds.forEach(metricId => {
        if (metricId.includes('_')) {
            metricIdsWithUnderscores.push(metricId);
        }
    });

    const metricCards = config.metricCards.filter((card) => {
        if (!configuredMetricIds.includes(card.metricId)) {
            missingMetricIdErrors.push(card.metricId);
            return false;
        }

        return true;
    });

    const filteredMetricCardIds = metricCards.map((card) => card.id);
    const sections = config.sections.map((section) => ({
        ...section,
        metricCards: section.metricCards
            // Remove refs to metric cards that do not have an entry in config.metricCards
            .filter((cardId) => {
                if (!configuredMetricCards.includes(cardId)) {
                    metricCardErrors.push(cardId);
                    return false;
                }

                return true;
            })
            // Remove refs to metric cards that have been filtered out b/c of an invalid metric ID
            .filter((cardId) => filteredMetricCardIds.includes(cardId))
    }));

    const reports = config.reports.filter((report) => {
        if (!configuredMetricIds.includes(report.metricId)) {
            missingMetricIdErrors.push(report.metricId);
            return false;
        }

        return true;
    });

    // Combine all config section errors
    const errorsWhichRequireRemoval: string[] = [];
    const errorsWhichDontRequireRemoval: string[] = []; // For errors that don't always lead to incorrectness
    if (missingMetricIdErrors.length > 0) {
        errorsWhichRequireRemoval.push(`metric IDs that don't exist: [${missingMetricIdErrors.join(', ')}]`);
    }
    if (metricCardErrors.length > 0) {
        errorsWhichRequireRemoval.push(`metric cards that don't exist: [${metricCardErrors.join(', ')}]`);
    }

    if (metricIdsWithUnderscores.length > 0) {
        errorsWhichDontRequireRemoval.push(`metric IDs that contain underscores: [${metricIdsWithUnderscores.join(', ')}]`);
    }

    const errorsWhichRequireRemovalString = (errorsWhichRequireRemoval.length > 0)
        ? `Error: Config references ${errorsWhichRequireRemoval.join(' and ')} `
            .concat('(Cards and Reports referencing these entries have been removed)')
        : undefined;

    const errorsWhichDontRequireRemovalString = (errorsWhichDontRequireRemoval.length > 0)
        ? `Error: Config references ${errorsWhichDontRequireRemoval.join(' and ')} `
            .concat('(Cards and Reports referencing these entries have not been removed but may behave incorrectly)')
        : undefined;

    const errorsStrings = [errorsWhichRequireRemovalString, errorsWhichDontRequireRemovalString].filter(s => s !== undefined);

    return {
        config: {
            ...config,
            metricCards,
            sections,
            reports,
        },
        errors: errorsStrings
    };
}
