import { ResultOf } from '@graphql-typed-document-node/core';
import type { PrettyIntersect } from 'common';
import type { Interval } from 'config-schema';
import * as t from 'io-ts';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
    DeleteApparatusInput,
    DeleteApparatusTypeInput,
    GeoMetadata,
    GetApiStatusDocument,
    GetApparatusDocument,
    GetApparatusTypesDocument,
    GetCategoricalMetricConfigDocument,
    GetCurrentStationsDocument,
    GetDateRangeTimestampsDocument,
    GetFileDocument,
    GetGeoMetadataDocument,
    GetGroupedMetricBreakdownDocument,
    GetHomeConfigDocument,
    GetHqFiltersDocument,
    GetIntervalMetricDistributionDocument,
    GetIntervalMetricStatsDocument,
    GetLineMetricByDateBinDocument,
    GetLineMetricStatsDocument,
    GetResponsePlanEntriesDocument,
    GetStaffTypeOptionsDocument,
    GetTablesRequiringSyncDocument,
    GetTargetByIdDocument,
    GetTargetsByGroupDocument,
    GetTimezoneDocument,
    GetUnmappedApparatusDocument,
    ListFilesDocument,
    SaveApparatusTypeSettingsDocument,
    UpdateTablesDocument, UpsertApparatusTypeInput,
} from '@/generated/graphql-operations';
import { parse } from '@api-client/helpers/types';
import type { TenantApiClient } from '../../TenantApiClient';
import { StandardizedNulls, standardizeNulls } from '../../helpers';
import { metricByDateBinQuery } from './queries/buildMetricByDateBinQuery';
import { metricSetQuery } from './queries/buildMetricSetQuery';
import { validateConfig } from './utils';

export type MetricFilters = Record<string, string[] | number[] | undefined>;

export type HqMetricSetQueryResult = {
    values: Record<string, Record<string, number | undefined>>;
    queries: Record<string, string | null>;
};

export type IntervalMetricStats = StandardizedNulls<ResultOf<typeof GetIntervalMetricStatsDocument>['api']['metricSet']['result']>;
export type LineMetricStats = StandardizedNulls<ResultOf<typeof GetLineMetricStatsDocument>['api']['metricSet']['result']>;
export type HqConfig = StandardizedNulls<ResultOf<typeof GetHomeConfigDocument>['api']['hqConfig']>;
export type HqTimeRange = HqConfig['timeRanges'][number];
export type HqInterval = HqTimeRange['interval'];
export type HqMetricConfig = HqConfig['metrics'][number];
export type HqMetricCardConfig = HqConfig['metricCards'][number];
export type HqReportConfig = HqConfig['reports'][number];
export type HqReportElementConfig = HqReportConfig['charts'][number];
export type HqChartConfig = Extract<HqReportElementConfig, { __typename: 'HqChartConfig' }>;
export type HqMetricCardsSectionConfig = Extract<HqReportElementConfig, { __typename: 'HqMetricCardsSectionConfig' }>;
export type HqUtilizationReports = HqConfig['utilizationReports'];
export type HqUnitHourUtilizationReport = NonNullable<HqUtilizationReports>['unitHourUtilizationReport'];
export type HqCallConcurrencyReport = NonNullable<HqUtilizationReports>['callConcurrencyReport'];
export type HqStationResponseReliabilityReport = NonNullable<HqUtilizationReports>['stationResponseReliabilityReport'];
export type HqDimensionConfig = Omit<(HqMetricConfig['dimensionConfig'] & Array<unknown>)[number], '__typename'>;
export type File = StandardizedNulls<ResultOf<typeof GetFileDocument>['api']['getFile']> & Record<string, any>;
export type HqTarget = StandardizedNulls<ResultOf<typeof GetTargetByIdDocument>['api']['getTargetById']>;
export type HqFilter = StandardizedNulls<ResultOf<typeof GetHqFiltersDocument>['api']['hqFilters'][number]>;
export type ApparatusType = PrettyIntersect<Omit<StandardizedNulls<ResultOf<typeof GetApparatusTypesDocument>['api']['apparatusTypes'][number]>, 'attributes'> & {
    attributes?: Record<string, boolean>
}>;
export type Apparatus = PrettyIntersect<Omit<StandardizedNulls<ResultOf<typeof GetApparatusDocument>['api']['apparatus'][number]>, 'attributes'> & {
    attributes?: Record<string, boolean>
}>;
export type UnmappedApparatus = StandardizedNulls<ResultOf<typeof GetUnmappedApparatusDocument>['api']['unmappedApparatus'][number]>;
export type MetricQueryFilter = {
    id: string;
    value: string[] | undefined;
};

const toFilterArray = (filters: MetricFilters): MetricQueryFilter[] => _.map(
    filters,
    (value, key) => ({
        id: key,
        value: value === undefined ? value : value.map((v) => `${v}`),
    })
);

const getTargetFilters = (targetId?: string) => (
    (targetId)
        ? [{ id: 'target_id', value: [targetId] }]
        : []
);

export type QueryResult<ResultType> = {
    query?: string;
    result: ResultType;
}

export class HomeApi {
    api: TenantApiClient;
    constructor(api: TenantApiClient) {
        this.api = api;
    }

    async getConfig(): Promise<HqConfig> {
        const { hqConfig } = standardizeNulls(await this.api.query(GetHomeConfigDocument, { tenantId: this.api.db }).then(r => r.api));

        const { config, errors } = validateConfig(hqConfig);
        errors.forEach(console.error);
        return config;
    }
    async getFilters() {
        const { hqFilters } = await this.api.query(GetHqFiltersDocument, { tenantId: this.api.db }).then(r => r.api);
        return standardizeNulls(hqFilters);
    }
    async getMetricSet(
        metricsByResponseGroup: Record<string, { id: string; metricId: string; targetId?: string }[]>,
        isoStartDate: string,
        isoEndDate: string,
        filters: MetricFilters = {}
    ) {
        const result = await this.api.query(metricSetQuery, {
            tenantId: this.api.db,
            metricsByResponseGroup,
            isoStartDate,
            isoEndDate,
            filters: toFilterArray(filters)
        }).then(r => r.api);
        return result;
    }
    async getMetricByDateBin(
        metric: string,
        groupingMetrics: string[],
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        subInterval: { unit: Interval; count: number },
        targetId?: string,
        filters: MetricFilters = {},
    ): Promise<QueryResult<{ bin: string; value?: number }[]>> {
        const { metricSetByDateBin } = await this.api.query(
            metricByDateBinQuery,
            {
                tenantId: this.api.db,
                metric,
                groupingMetrics,
                responseGroup,
                isoStartDate,
                isoEndDate,
                subInterval: {
                    unit: subInterval.unit,
                    count: subInterval.count
                },
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(metricSetByDateBin);
    }
    async getLineMetricByDateBin(
        metric: string,
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        subInterval: { unit: Interval; count: number },
        targetId?: string,
        filters: MetricFilters = {}
    ): Promise<QueryResult<{ bin: string; value?: number; responseVolume?: number }[]>> {
        const { metricSetByDateBin } = await this.api.query(
            GetLineMetricByDateBinDocument,
            { tenantId: this.api.db,
                metric,
                responseGroup,
                groupingMetrics: [],
                isoStartDate,
                isoEndDate,
                subInterval,
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(metricSetByDateBin);
    }
    async getLineMetricStats(
        metric: string,
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        targetId?: string,
        filters: MetricFilters = {}
    ): Promise<QueryResult<LineMetricStats>> {
        const { metricSet: lineMetricStats } = await this.api.query(
            GetLineMetricStatsDocument,
            {
                tenantId: this.api.db,
                metric,
                responseGroup,
                isoStartDate,
                isoEndDate,
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(lineMetricStats);
    }
    async getIntervalMetricDistribution(
        metric: string,
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        targetId?: string,
        lastBinLowerBound = 240,
        lastBinPercentile = 0.98,
        filters: MetricFilters = {}
    ): Promise<QueryResult<{ bin: string; value?: number }[]>> {
        const { intervalMetricDistribution } = await this.api.query(
            GetIntervalMetricDistributionDocument,
            {
                tenantId: this.api.db,
                metric,
                responseGroup,
                isoStartDate,
                isoEndDate,
                lastBinLowerBound,
                lastBinPercentile,
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(intervalMetricDistribution);
    }
    async getIntervalMetricStats(
        metric: string,
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        targetId?: string,
        filters: MetricFilters = {}
    ): Promise<QueryResult<IntervalMetricStats>> {
        const { metricSet: intervalMetricStats } = await this.api.query(
            GetIntervalMetricStatsDocument,
            {
                tenantId: this.api.db,
                metric,
                responseGroup,
                isoStartDate,
                isoEndDate,
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(intervalMetricStats);
    }
    async getGroupedMetricBreakdown(
        metricId: string,
        groupingMetricIds: string[],
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        targetId?: string,
        filters: MetricFilters = {}
    ) {
        const { groupedMetricBreakdown } = await this.api.query(
            GetGroupedMetricBreakdownDocument,
            {
                tenantId: this.api.db,
                metricId,
                groupingMetricIds,
                responseGroup,
                isoStartDate,
                isoEndDate,
                filters: toFilterArray(filters),
                targetFilters: getTargetFilters(targetId),
            }
        ).then(r => r.api);
        return standardizeNulls(groupedMetricBreakdown);
    }
    async getCategoricalMetricConfig(metricId: string): Promise<HqDimensionConfig[]> {
        const { categoricalMetricConfig: { result } } = await this.api.query(GetCategoricalMetricConfigDocument, { tenantId: this.api.db, metricId }).then(r => r.api);
        return standardizeNulls(result) as HqDimensionConfig[];
    }
    async getTimezone(): Promise<string> {
        const { timezone } = await this.api.query(GetTimezoneDocument, { tenantId: this.api.db }).then(r => r.api);
        return timezone;
    }
    async getGeoMetadata(): Promise<GeoMetadata> {
        const { geoMetadata } = await this.api.query(GetGeoMetadataDocument, { tenantId: this.api.db }).then(r => r.api);
        return geoMetadata;
    }
    async getDateRangeTimestamps(): Promise<[DateTime, DateTime]> {
        const { dateRangeTimestamps, timezone } = await this.api.query(GetDateRangeTimestampsDocument, { tenantId: this.api.db }).then(r => r.api);
        return [
            DateTime.fromISO(dateRangeTimestamps[0]).setZone(timezone),
            DateTime.fromISO(dateRangeTimestamps[1]).setZone(timezone)
        ];
    }
    async getTargetById(targetId: string) {
        const { getTargetById: target } = await this.api.query(GetTargetByIdDocument, { tenantId: this.api.db, targetId }).then(r => r.api);
        return standardizeNulls(target);
    }
    async getTargetsByGroup(targetGroup: string) {
        const { getTargetsByGroup: targets } = await this.api.query(GetTargetsByGroupDocument, { tenantId: this.api.db, targetGroup }).then(r => r.api);
        return standardizeNulls(targets);
    }
    async getApparatusTypes(): Promise<ApparatusType[]> {
        const { apparatusTypes } = standardizeNulls(await this.api.query(GetApparatusTypesDocument, { tenantId: this.api.db }).then(r => r.api));
        return apparatusTypes.map((apparatusType) => ({
            ...apparatusType,
            attributes: parse(
                t.union([t.record(t.string, t.boolean), t.undefined]),
                apparatusType.attributes,
            ),
        }));
    }
    async getApparatus(): Promise<Apparatus[]> {
        const { apparatus } = standardizeNulls(await this.api.query(GetApparatusDocument, { tenantId: this.api.db }).then(r => r.api));
        return apparatus.map((apparatusEntry) => ({
            ...apparatusEntry,
            attributes: parse(
                t.union([t.record(t.string, t.boolean), t.undefined]),
                apparatusEntry.attributes,
            ),
        }));
    }
    async saveApparatusTypeSettings(
        deleted: DeleteApparatusTypeInput[],
        upserted: UpsertApparatusTypeInput[],
    ) {
        const { result } = await this.api.query(
            SaveApparatusTypeSettingsDocument,
            { tenantId: this.api.db, deleted, upserted }
        );
        return standardizeNulls(result);
    }

    async getCurrentStations() {
        const { currentStations } = await this.api.query(GetCurrentStationsDocument, { tenantId: this.api.db }).then(r => r.api);
        return standardizeNulls(currentStations);
    }
    async getStaffTypeOptions() {
        const { staffTypeOptions } = await this.api.query(GetStaffTypeOptionsDocument, { tenantId: this.api.db }).then(r => r.api);
        return standardizeNulls(staffTypeOptions);
    }
    async updateTables(): Promise<void> {
        await this.api.query(UpdateTablesDocument, { tenantId: this.api.db });
    }
    async getApiStatus() {
        const { api } = await this.api.query(GetApiStatusDocument, { tenantId: this.api.db });

        return standardizeNulls(api);
    }
    async getTablesRequiringSync() {
        const { api } = await this.api.query(GetTablesRequiringSyncDocument, { tenantId: this.api.db });

        return standardizeNulls(api.tablesRequiringSync);
    }
    async listFiles(type: string): Promise<File[]> {
        const { listFiles } = await this.api.query(ListFilesDocument, { tenantId: this.api.db, type }).then(r => r.api);
        return listFiles;
    }
    async getFile(type: string): Promise<File | undefined> {
        const { getFile } = await this.api.query(GetFileDocument, { tenantId: this.api.db, type }).then(r => r.api);
        return standardizeNulls(getFile);
    }
    async getResponsePlanEntries() {
        return this.api.query(GetResponsePlanEntriesDocument, { tenantId: this.api.db });
    }
}
