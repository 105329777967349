import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useTenantId = () => {
    const route = useRoute();

    return computed(() => {
        const value = route.params.tenant;

        if (value === undefined) {
            return undefined;
        }

        return Array.isArray(value) ? value[0] : value;
    });
};
