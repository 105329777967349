import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { daAuthClient } from '@api-client';

const Sidebar = () => import('@/views/Sidebar');
const EmptyContainer = () => import('@/views/EmptyContainer.vue');

const AppPickerPage = () => import('@/pages/AppPicker');
const TenantPickerPage = () => import('@/pages/TenantPicker');
const DashboardAppComponent = () => import('@/pages/Dashboard/DashboardApp.vue');

export const route = {
    path: '/dashboard',
    components: {
        sidebar: Sidebar,
        default: EmptyContainer
    },
    props: {
        sidebar: {
            app: 'Dashboards'
        }
    },
    children: [{
        name: 'logout',
        path: 'logout',
        redirect: () => {
            daAuthClient.logout();
            return '/dashboard/login';
        }
    }, {
        path: '',
        component: DashboardAppComponent,
        name: 'DashboardApp',
        children: [
            {
                path: '',
                name: 'DashboardsDatabasePicker',
                component: TenantPickerPage,
                props: {
                    title: 'Dashboards',
                    app: 'Dashboards'
                },
                beforeEnter: redirectToValidRouteForUser({ appName: 'Dashboards' })
            },
            {
                // Any route that doesn't start with /login. Ideally, we'd match everything but _exactly_
                // login, but Vue wraps this regex in some of its own syntax which breaks a naive "everything but
                // login" regex.
                path: ':tenant((?!login\\)[^/]+)',
                name: 'DashboardTenant',
                children: [
                    {
                        path: '',
                        name: 'DashboardPicker',
                        component: AppPickerPage,
                        props: {
                            appName: 'Dashboards',
                            title: 'Dashboards',
                            onSelectedRoute: 'DashboardAppMain',
                            appRouteParameter: 'dashboard'
                        },
                        beforeEnter: redirectToValidRouteForUser({ appName: 'Dashboards' })
                    },
                    {
                        name: 'DashboardAppMain',
                        path: ':dashboard',
                        redirect: { name: 'overview' }
                    },
                    {
                        path: ':dashboard/stats/:metric',
                        name: 'stats',
                        component: () => import('@dashboard/views/Stats/StatsContainer.vue')
                    },
                    {
                        path: ':dashboard/overview',
                        name: 'overview',
                        component: () => import('@dashboard/views/Overview/Overview.vue'),
                        props: {
                            default: false,
                            sidebar: true
                        }
                    }
                ]
            }
        ]
    }],
    meta: { appName: 'Dashboard' }
};
