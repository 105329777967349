import { App } from 'vue';

// eslint-disable-next-line no-alert
let globalConfirmHandler = async (message?: string) => window.confirm(message);

export const confirm = (message?: string) => globalConfirmHandler(message);

export function setupGlobals(vueApp: App<Element>) {
    vueApp.config.globalProperties.confirm = (message?: string) => globalConfirmHandler(message);
    vueApp.provide('setConfirmHandler', (handler: (message?: string) => Promise<boolean>) => {
        globalConfirmHandler = handler;
    });
}
